import React, { Component } from 'react';
import './pictureViewer.css';
import PropTypes from 'prop-types';

class PictureViewer extends Component {
    state = {
      modalOpen: false
    }

    toggleModal = () => {
      if (this.state.modalOpen) {
        this.setState({modalOpen: false});
      } else {
        this.setState({modalOpen: true});
      }
      this.props.handleSetImage(this.props.thumbnail);
    }
    render() {
      return this.props.fullView ? (
        <div className="PictureViewer">
          {this.props.image === this.props.thumbnail ?
            <div className='picture-display-full'>
              <div className='picture-and-arrows'>
                {this.props.previous ? 
                  <div 
                    className='arrow-display-full left-display-full' 
                    onClick={() => this.props.handleSetImage(this.props.previous)} /> 
                  : <div style={{width: '2em', height: '2em', backgroundColor: 'white'}} />}
                <img 
                  className='modal-image-display-full'
                  src={this.props.image} 
                  alt={this.props.caption} />
                {this.props.next ? 
                  <div 
                    className='arrow-display-full right-display-full' 
                    onClick={() => this.props.handleSetImage(this.props.next)} /> 
                  : <div style={{width: '2em', height: '2em', backgroundColor: 'white'}} />}
              </div>
            </div> : null}
          {/* <img 
            className='thumbnail-display-full'
            src={this.props.thumbnail} 
            alt={this.props.caption}
            onClick={this.toggleModal} /> */}
        </div>
      ) : (
        <div className="PictureViewer">
          {this.state.modalOpen ? <div className='picture-modal'>
            <div className='x-box' onClick={this.toggleModal}>X</div>
            <div className='picture-and-arrows'>
              {this.props.previous ? 
                <div className='arrow left' onClick={() => this.props.handleSetImage(this.props.previous)} /> 
                : null}
              <img 
                className='modal-image'
                src={this.props.image} 
                alt={this.props.caption} />
              {this.props.next ?
                <div className='arrow right' onClick={() => this.props.handleSetImage(this.props.next)} />
                : null}
            </div>
          </div> : null}
          <img 
            className='thumbnail'
            src={this.props.thumbnail} 
            alt={this.props.caption}
            onClick={this.toggleModal} />
        </div>
      );
    }
}

PictureViewer.propTypes = {
  image: PropTypes.string,
  thumbnail: PropTypes.string,
  caption: PropTypes.string,
  previous: PropTypes.any,
  next: PropTypes.any,
  handleSetImage: PropTypes.func,
  fullView: PropTypes.bool
};

export default PictureViewer;