import React, { Component } from 'react';
import '../App.css';
// import Slider from './Slider';
import {imagesList} from '../lib/sdk';
import PictureViewer from './PictureViewer';

class Gallery extends Component {
  state = {
    currentImage: imagesList[0]
  }
  handleSetImage = (image) => {
    this.setState({ currentImage: image });
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="Gallery">
        {/* <Slider /> */}
        <div className='thumbnail-box'>
          {imagesList.map(pic => <PictureViewer
            fullView
            key={pic}
            handleSetImage={this.handleSetImage}
            thumbnail={pic} 
            image={this.state.currentImage} 
            previous={imagesList[imagesList.indexOf(this.state.currentImage) - 1] || null} 
            next={imagesList[imagesList.indexOf(this.state.currentImage) + 1] || null} 
            caption='' />)}
        </div>
        <div className='thumbnail-box'>
          {imagesList.map(pic => <img 
            key={pic}
            className='thumbnail-display-full'
            src={pic} 
            alt={''}
            onClick={() => this.handleSetImage(pic)} />)}
        </div>
      </div>
    );
  }
}

export default Gallery;