import React, { Component } from 'react';
// import PuppyBio from './PuppyBio';
import '../App.css';
// import Puppy1Pic from '../images/p1.jpg';
// import Puppy2Pic from '../images/p2.jpg';
// import Puppy3Pic from '../images/p3.jpg';
// import Puppy4Pic from '../images/p4.jpg';
import ReactPlayer from 'react-player';
import PuppyWreath from '../images/weekly/weekFour/puppy-wreath.jpg';


class Puppies extends Component {
  render() {
    return (
      <div className="Puppies container container-main">
        <div className="row">
          <div className='col-md-1' />
          <div className='col-md-10'>
            <h3 style={{textAlign: 'center'}}>Bella and Milosz puppies, born April 14, 2022</h3>
          </div>
          <div className='col-md-1' />
        </div>
        <div className="row">
          <div className='col-md-1' />
          <div className="col-md-10" style={{width: '100%'}}>
            <img 
              className='video-player'
              src={PuppyWreath} 
              alt='wreath of puppies' 
              style={{
                display: 'block',
                maxWidth: '100%',
                maxHeight: '100%',
                width: 'auto',
                height: 'auto',
                marginLeft: 'auto',
                marginRight: 'auto'
              }} />
            {/* <h4>Week Four</h4> */}
          </div>
          {/* <div className='col-md-6' /> */}
          {/* <div className="col-md-4 video-player" style={{width: '100%'}}>
            <ReactPlayer 
              className='react-player'
              url={'https://vimeo.com/707277374'}
              // light
              controls
              width='100%'
              height='100%'
            />
            <h4>Week Three</h4>
          </div> */}
          <div className='col-md-1' />
        </div>
        <div className="row">
          <div className='col-md-1' />
          <div className="col-md-4 video-player" style={{width: '100%'}}>
            <ReactPlayer 
              className='react-player'
              url={'https://vimeo.com/709646874'}
              // light
              controls
              width='100%'
              height='100%'
            />
            <h4>Week Four</h4>
          </div>
          <div className='col-md-2' />
          <div className="col-md-4 video-player" style={{width: '100%'}}>
            <ReactPlayer 
              className='react-player'
              url={'https://vimeo.com/707277374'}
              // light
              controls
              width='100%'
              height='100%'
            />
            <h4>Week Three</h4>
          </div>
          <div className='col-md-1' />
        </div>

        <div className="row">
          <div className='col-md-1' />
          <div className="col-md-4 video-player" style={{width: '100%'}}>
            <ReactPlayer 
              className='react-player'
              url={'https://vimeo.com/706379825'}
              // light
              controls
              width='100%'
              height='100%'
            />
            <h4>Week Two</h4>
          </div>
          <div className='col-md-2' />
          <div className="col-md-4 video-player" style={{width: '100%'}}>
            <ReactPlayer 
              className='react-player'
              url={'https://vimeo.com/706379396'}
              controls
              width='100%'
              height='100%'
            />
            <h4>Week One</h4>
          </div>
          <div className='col-md-1' />
        </div>
      </div>
    );
  }
}

export default Puppies;