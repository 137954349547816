import Puppy1 from '../../images/puppy1.JPG';
import Puppy2 from '../../images/puppy2.JPG';
import Puppy3 from '../../images/puppy3.JPG';
import Puppy4 from '../../images/puppy4.JPG';
import Puppy5 from '../../images/puppy5.JPG';
import SittingSweetly from '../../images/sitting-sweetly.PNG';
import Playing from '../../images/playing.JPG';
import Headshot from '../../images/headshot.JPG';
import Pic1 from '../../images/8A4.JPG';
import Pic2 from '../../images/412A9816.jpeg';
import Pic3 from '../../images/2001.JPG';
import Pic4 from '../../images/DSC03709.jpeg';
import Pic5 from '../../images/IMG_0581.JPG';
import Pic6 from '../../images/IMG_1967.JPG';
import Pic7 from '../../images/IMG_1972.jpeg';
import Pic8 from '../../images/IMG_2592.jpg';
import Pic9 from '../../images/IMG_2602.jpg';
import Pic10 from '../../images/IMG_2605.jpg';
import Pic11 from '../../images/IMG_2611.jpg';
import Pic12 from '../../images/IMG_2612.jpg';
import Pic13 from '../../images/IMG_2613.jpg';
import Pic14 from '../../images/IMG_4091.JPG';
import Pic15 from '../../images/IMG_4092.JPG';
import Pic16 from '../../images/IMG_4093.JPG';
import Pic17 from '../../images/IMG_4264.JPG';
import Pic18 from '../../images/IMG_4265.JPG';
import Pic19 from '../../images/IMG_4266.JPG';
import Pic20 from '../../images/IMG_4378.JPG';
import Pic21 from '../../images/IMG_4389.JPG';
import Pic22 from '../../images/IMG_4398.jpeg';
import Pic23 from '../../images/IMG_4548.JPG';
import Pic24 from '../../images/IMG_4561.JPG';
import Pic25 from '../../images/IMG_4600.jpeg';
import Pic26 from '../../images/IMG_4616.JPG';
import Pic27 from '../../images/IMG_4629.JPEG';
import Pic28 from '../../images/IMG_4640.JPEG';
import Pic29 from '../../images/IMG_5139.jpeg';
import Pic30 from '../../images/IMG_5162.JPG';
import Pic31 from '../../images/IMG_5174.JPG';
import Pic32 from '../../images/IMG_5204.JPG';
import Pic33 from '../../images/IMG_5342.JPG';
import Pic34 from '../../images/IMG_5365.jpeg';
import Pic35 from '../../images/IMG_5476.JPG';
import Pic36 from '../../images/IMG_5567.jpeg';
import Pic37 from '../../images/IMG_5588.JPG';
import Pic38 from '../../images/IMG_5636.jpeg';
import Pic39 from '../../images/IMG_5691.JPG';
import Pic40 from '../../images/IMG_6405.jpeg';
import Pic41 from '../../images/IMG_6407.jpeg';
import Pic42 from '../../images/IMG_6599.jpeg';
import Pic43 from '../../images/IMG_6675.JPG';
import Pic44 from '../../images/IMG_6676.JPG';
import Pic45 from '../../images/IMG_6677.JPG';
import Pic46 from '../../images/IMG_6969.JPG';
import Pic47 from '../../images/IMG_6976.jpeg';
import Pic48 from '../../images/IMG_6990.JPG';
import Pic49 from '../../images/IMG_6991.JPG';
import Pic50 from '../../images/IMG_7065.JPG';
import Pic51 from '../../images/IMG_7188.jpeg';
import Pic52 from '../../images/show1.JPG';
import Pic53 from '../../images/show2.JPG';
import Pic54 from '../../images/pumpkin.JPEG';
import Pic55 from '../../images/pumpkin1.JPEG';
import Pic56 from '../../images/pumpkin2.JPEG';
import Pic57 from '../../images/pumpkin3.JPEG';
import Pic58 from '../../images/pumpkin4.JPEG';
import Pic59 from '../../images/p4.jpg';

export const imagesList = [
  Puppy1,
  Puppy2,
  Puppy3,
  Puppy4,
  Puppy5,
  SittingSweetly,
  Playing,
  Headshot,
  Pic1,
  Pic2,
  Pic3,
  Pic4,
  Pic5,
  Pic6,
  Pic7,
  Pic8,
  Pic9,
  Pic10,
  Pic11,
  Pic12,
  Pic13,
  Pic14,
  Pic15,
  Pic16,
  Pic17,
  Pic18,
  Pic19,
  Pic20,
  Pic21,
  Pic22,
  Pic23,
  Pic24,
  Pic25,
  Pic26,
  Pic27,
  Pic28,
  Pic29,
  Pic30,
  Pic31,
  Pic32,
  Pic33,
  Pic34,
  Pic35,
  Pic36,
  Pic37,
  Pic38,
  Pic39,
  Pic40,
  Pic41,
  Pic42,
  Pic43,
  Pic44,
  Pic45,
  Pic46,
  Pic47,
  Pic48,
  Pic49,
  Pic50,
  Pic51,
  Pic52,
  Pic53,
  Pic54,
  Pic55,
  Pic56,
  Pic57,
  Pic58,
  Pic59,
];